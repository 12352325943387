import React from 'react'
import Layout from "../components/layout-black"
import "../components/styles/cost.scss"
import { AnimatedLine } from '../subitems/portfolio/animatedLine'
import Seo from "../components/seo"
import { CalculatorLink } from "../components/calculator/calculatorLink"
import { SchemaCost } from "../subitems/schema-cost"

import { Video } from "../subitems/cost-video/video"
import CostVideo from "../video/cost_video.mp4"
import CostVideoMobile from "../video/cost_video_mob.mp4"


const Cost = () => {
  return (
    <Layout>
      <Seo  title={"Стоимость дизайн проекта | I-PROJECT"} description={"Стоимость услуги дизайн проект: что влияет на стоимость"}
            location={"https://i-project.by/cost"}
            image={"https://i-project.by/portfolio_newsite/korolya/01_korolya.webp"}/>
      <div class="wrapper">
        <h1 class="cost_title">Стоимость дизайн проекта</h1>
        <AnimatedLine />
      </div>
      <div class="wrapper">
        <div className="cost_text">
          <p class="cost_text_p">Основную ценность наших услуг можно показать через схему реализации дизайн проекта. Мы&nbsp;находимся максимально близко к&nbsp;клиенту и&nbsp;помогаем решать ему вопросы, которые возникают в&nbsp;процессе реализации.</p>
          <p class="cost_text_p">Помимо вопросов поставки материалов или контроля качества работы, нужно следить за&nbsp;тем, чтобы подрядчики не&nbsp;создавали проблем для специалистов из&nbsp;смежных областей. Оперативная реакция на&nbsp;возникающие проблемы и&nbsp;продуманный микроменджмент позволили довести до&nbsp;результата большое количество квартир и&nbsp;домов.</p>
        </div>
        <SchemaCost />
        <Video mobileSrc={CostVideoMobile} desktopSrc={CostVideo} />
        <CalculatorLink />
      </div>
    </Layout>
  )
}

export default Cost